import { Brand } from './typings'

const br: Brand = {
  clientInfo: {
    displayName: 'Banana Republic',
    accountNumberLengths: [16],
    footerDisplay: 'BananaRepublic.com',
    footerLink: 'https://www.BananaRepublic.com/',
    applicationUrl: '',
    dsecApigeeClientId: {
      development: 'P1DgbB8GdXRF6zLSF2a8gw86UYEcrwDU',
      production: 'q1py8HhastnxAX1wRsjVM3kQAfJhZCrD'
    },
    persistApigeeClientId: { development: '', production: '' },
    setpayApigeeClientId: {
      development: '',
      production: ''
    },
    metaTagCardTitle: {
      en: 'Credit Card',
      es: 'Tarjeta de Crédito'
    },
    metaTagCardDescription: {
      en: `Log into your Banana Republic credit card account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`,
      es: `Ingrese a su cuenta de la Tarjeta de Crédito Banana Republic en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
    },
    languages: ['en'],
    vanityUrl: 'BananaRepublic.com',
    hostname: 'BananaRepublic.syf.com',
    linkingRedirect: '',
    ivaKey: '',
    hasMss: true,
    hasCpl: false,
    promoUrl: 'https://bananarepublic.barclaysus.com',
    hasCplOldErrorCode: false,
    isMultiAccount: false,
    footerContent: {
      en: 'Your Banana Republic Rewards Visa® Credit Card, Banana Republic Rewards Credit Card, or Gap Inc. Visa Signature® Card is issued by Synchrony Bank. The Synchrony Bank Privacy Policy governs the use of the Banana Republic Rewards Visa® Credit Card, Banana Republic Rewards Credit Card, or Gap Inc. Visa Signature® Card. The use of this site is governed by the use of the Synchrony Bank Online Privacy Policy, which is different from the privacy policy of Banana Republic.',
      es: ''
    },
    phoneNumbers: {
      loan: '',
      plcc: '1-800-234-7455',
      dualcard: '1-866-450-2330',
      fraud: '1-877-350-4039',
      customerService: '1-855-622-2695'
    },
    iOSAppId: '',
    androidApp: {
      id: '',
      author: '',
      title: '',
      image: ''
    },
    crossBorder: false,
    cplHeroBannerDisplayName: '',
    termsOfService: {
      en: '/essimages/cs/groups/ess_webasset/generic/@br/en/html/ess003081.html',
      es: ''
    },
    onlinePrivacy: {
      en: '/essimages/cs/groups/ess_webasset/generic/@br/en/html/ess013955.html',
      es: ''
    },
    privacyPolicy: {
      en: '/essimages/cs/groups/ess_webasset/generic/@br/en/html/ess013951.html',
      es: ''
    },
    websiteUsage: {
      en: '/essimages/cs/groups/ess_webasset/generic/@br/en/html/ess013953.html',
      es: ''
    },
    voice: {
      cardName: '',
      google: {
        termsAndConditionsLink: ''
      },
      alexa: {
        termsAndConditionsLink: ''
      }
    }
  },
  palette: {
    field: {
      icon: '#665E47',
      focus: '#34657F',
      label: ''
    },
    progressStepBar: {
      fill: '#333333',
      label: '#333333'
    },
    progressBar: {
      complete: '',
      error: '#c63527',
      inProgress: '#0072ad',
      background: '#e9eaeb'
    },
    button: {
      primary: {
        text: '#FFFFFF',
        hover: '#adadad'
      },
      secondary: {
        hover: '#d9d9d9'
      },
      background: '#000000'
    },
    checkbox: {
      background: '#979797',
      font: '#FFFFFF'
    },
    switch: { background: '' },
    heroBackground: '#FFFFFF',
    bodyBackground: '#FFFFFF',
    iconBackground: '#333333',
    headerBackground: '#FFFFFF',
    modalBackground: '#FFFFFF',
    formBackground: '',
    headerDivider: '#665E47',
    footerDivider: '#000000',
    contrastingText: '#FFFFFF',
    bodyText: '#333333',
    languageSelector: '#333333',
    focus: '',
    link: '#665E47',
    footer: {
      background: '#F2F2F2',
      text: '#333333',
      link: '#333333'
    }
  },
  images: {
    logo: {
      en: '/assets/br/logo.jpg',
      es: '/assets/br/logo.jpg'
    },
    hero: '',
    heroBgDesktop: '',
    heroBgMobile: '',
    mainPromo: {
      en: '/assets/br/promo.png',
      es: '/assets/br/promo.png'
    },
    voiceCplLogo: ''
  }
}

export default br
